import React from "react"

import Img from 'gatsby-image'

import styles from './NonStretchedImage.module.css'

export const NonStretchedImage = props => {
  const { photo: { Img: { childImageSharp: { fluid }}, Description}, className} = props;
  let normalizedProps = { fluid };

  if (fluid && fluid.presentationWidth) {
    normalizedProps = {
      fluid,
      style: {
        maxWidth: fluid.presentationWidth,
        maxHeight: fluid.presentationHeight,
        margin: "0 auto", // Used to center the image
      },
    }
  }

  return (
    <div
      style={{maxWidth: fluid.presentationWidth, maxHeight: fluid.presentationHeight}}
      className={`${styles.imageObj} ${className}`}
    >
      <Img className={styles.image} {...normalizedProps} />
      {Description && <p className={styles.caption}>{Description}</p>}
    </div>
  );
}

export default NonStretchedImage;
