import React from "react"
import PropTypes from "prop-types"

import Header from '../Header';

import styles from './ArticleHeader.module.css'

const ArticleHeader = ({ title }) => (
  <Header id="start" article backLink="/home">
    <div className={styles.content}>
      <h1 className={styles.title}>{title}</h1>
    </div>
  </Header>
);

ArticleHeader.propTypes = {
  photo: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default ArticleHeader
