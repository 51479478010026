import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout/Layout'
import ArticleHeader from '../components/ArticleHeader';
import Container from '../components/Container';
import ImageContainer from '../components/ImageContainer';
import ArrowUp from '../components/ArrowUp';

const ArticleTemplate = ({ data: { strapiArticle: { Title, TopDescription, TopImage, Content, Content2, photos }}}) => (
  <Layout column>
    <ArticleHeader
      title={Title}
    />
    {Content && (
      <Container
        TopImage={TopImage}
        TopDescription={TopDescription}
        content={Content}
        content2={Content2}
      />
    )}
    {photos && (<ImageContainer photos={photos.sort((photo1, photo2) => photo1.Title < photo2.Title ? -1 : 1)} />)}
    <ArrowUp />
  </Layout>
)

export default ArticleTemplate

export const query = graphql`
  query ArticleTemplate($id: String!) {
    strapiArticle(id: {eq: $id}) {
      Title
      TopDescription
      TopImage {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Content
      Content2
      photos {
        Description
        Title
        Width
        Img {
          childImageSharp {
            fluid(maxWidth: 1300) {
              ...GatsbyImageSharpFluid
              presentationWidth
            }
          }
        }
      }
    }
  }
`
