import PropTypes from "prop-types"
import React from "react"
import ReactMarkdown from "react-markdown"
import Img from 'gatsby-image'

import styles from './Container.module.css'

const Container = ({ TopImage, TopDescription, content, content2 }) => (
  <div id="artykul" className={styles.self}>
    {(TopImage || TopDescription) && (
      <div className={styles.topContainer}>
        {TopImage && (
          <div className={styles.topImage}>
            <Img fluid={TopImage.childImageSharp.fluid} />
          </div>
        )}
        {TopDescription && (
          <div className={styles.topDescription}>
            <ReactMarkdown source={TopDescription} />
          </div>
        )}
      </div>
    )}
    <div className={styles.contentWrapper}>
      <div className={content2 ? styles.columnLeft : styles.onlyColumn}>
        <ReactMarkdown source={content} />
      </div>
      {content2 && (
        <div className={styles.columnRight}>
          <ReactMarkdown source={content2} />
        </div>
      )}
    </div>
  </div>
);

Container.propTypes = {
  photo: PropTypes.object,
  title: PropTypes.object,
};

export default Container
