import React from "react"
import PropTypes from "prop-types"

import styles from './ImageContainer.module.css'

import NonStretchedImage from '../NonStretchedImage';

function renderFullwidth(set) {
  return <div className={styles.full}>
    {set.photos.map(photo => <NonStretchedImage photo={photo} />)}
  </div>;
}

function renderHalfwidth(set) {
  return <div className={styles.half}>
    <div className={styles.firstCol}>
      {renderHalfColumn(set.firstCol)}
    </div>
    <div className={styles.secondCol}>
      {renderHalfColumn(set.secondCol)}
    </div>
  </div>;
}

function renderHalfColumn(photos) {
  return photos.map(photo => (
      <NonStretchedImage photo={photo} />
  ));
}

const ImageContainer = ({ photos }) => {
  const arrayMapped = [];
  for (let i = 0; i<photos.length; i++) {
    const photo = photos[i];
    const lastElement = arrayMapped[arrayMapped.length - 1];

    if (photo.Width === '2 columns') {
      if (lastElement && lastElement.type === 'fullwidth') {
        lastElement.photos.push(photo);
      } else {
        arrayMapped.push({
          type: 'fullwidth',
          photos: [photo],
        });
      }
    } else {
      if (lastElement && lastElement.type === 'halfwidth') {
        if (lastElement.firstCol.length === lastElement.secondCol.length) {
          lastElement.firstCol.push(photo);
        } else {
          lastElement.secondCol.push(photo);
        }
      } else {
        arrayMapped.push({
          type: 'halfwidth',
          firstCol: [photo],
          secondCol: [],
        });
      }
    }
  }

  return (
    <div id="galeria" className={styles.self}>
      <div className={styles.containerMobile}>
        {photos.map(photo => <NonStretchedImage photo={photo} />)}
      </div>
      <div className={styles.containerDesktop}>
        {arrayMapped.map(set => set.type === 'fullwidth' ? renderFullwidth(set) : renderHalfwidth(set))}
      </div>
    </div>
)};

ImageContainer.propTypes = {
  photos: PropTypes.arrayOf(PropTypes.object),
};

export default ImageContainer;
