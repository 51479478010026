import React from "react"

import styles from './ArrowUp.module.css'

import ArrowUpIcon from '../../assets/images/arrow-up.svg';

const scrollToTop = () => {
  document.body.scrollIntoView({ behavior: "smooth" });
};

const ArrowUp = () => (
  <div className={styles.self}>
    <div onClick={scrollToTop} className={styles.arrowUpWrapper}>
      <img className={styles.arrowUp} src={ArrowUpIcon} alt="Go to top" />
    </div>
  </div>
);

export default ArrowUp
