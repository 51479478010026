import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import Logo from '../../assets/images/trelinski.svg';
import LeftText from '../../assets/images/wyobrazone_vector.svg';
import Arrow from '../../assets/images/arrow-back.svg';

import styles from './Header.module.css'

const defaultLeftText = (
  <div className={styles.leftTextWrapper}>
    <img className={styles.leftText} src={LeftText} alt="Wyobrażone zmienia rzeczywiste" />
  </div>
);

const logoImg = <img className={styles.logo} src={Logo} alt="Treliński" />;

const Header = ({ children, backLink, article }) => (
  <header className={`${styles.self} ${article && styles.article}`}>
    {children || defaultLeftText}
    {backLink ?
      <Link className={styles.logoWrapper + ' ' + styles.backLink} to={backLink}>
        {logoImg}
        <div className={styles.arrowWrapper}><img className={styles.arrow} src={Arrow} alt="Go back" /></div>
      </Link>
      :
      <div className={styles.logoWrapper}>{logoImg}</div>
    }
  </header>
);

Header.propTypes = {
  backLink: PropTypes.string,
};

export default Header
